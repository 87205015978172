@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;700&display=swap');
@import url('./fonts/style.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    font-family: 'Prompt', sans-serif;
}

body {
    background-image: url('./images/bg.png');
    background-position: center center;
    background-size: 100%;
    background-size: cover;
    height: 100vh;
}

@media (max-width: 768px) {
    body {
        background-image: url('./images/bg-mobile.png');
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
    }
}


/* width */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #E80000
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input {
    outline: none;
}